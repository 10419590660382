const JUMP_HEIGHT = -18;
const DASH_VELOCITY = 15;
const BOUNCE = 0.6

class Player {
	constructor(scene,keys, label) {
		this.scene = scene;
		this.jumpKey = keys.jump;
		this.dashKey = keys.dash;
		this.brakeKey = keys.brake;
		this.label = label;
	}

	create(){
		let color = this.label === 'player1' ? 0x33468D : 0xD8345F;
		let circle =  this.scene.add.circle(20,20,20, color);

		let physicsBody = this.scene.matter.add.circle(20,20,20);

		this.player = this.scene.matter.add.gameObject(circle, physicsBody);
		this.player.setBounce(BOUNCE, BOUNCE);

		this.player.body.label = this.label;

		this.playerJump = false;
		this.playerJumpCount = 0;
	}
	update(){
		if (this.jumpKey.isDown && !this.playerJump && this.playerJumpCount < 2) {
			this.player.setVelocityY(JUMP_HEIGHT);
			this.playerJump = true;
			this.playerJumpCount++;
			
			this.scene.time.delayedCall(2500, ()=>{
				this.playerJumpCount--;
				if (this.playerJumpCount < 0) {
					this.playerJumpCount = 0;
				}
			});
		}
		if (this.dashKey.isDown) {
			this.player.setVelocityX(DASH_VELOCITY);
		}
		if (this.brakeKey.isDown) {
			this.player.setVelocityX(0);
		}	
		if (this.jumpKey.isUp) {
			this.playerJump = false;
		}
		this.player.x = this.player.x - 10;

		if (this.player.x < 0) {
			this.player.x = 0;
		}
		if (this.player.x > 1000) {
			this.player.x = 1000;
		}
	}
}

export default Player;