import Phaser from 'phaser';

class StartScene extends Phaser.Scene {
	constructor() {
		super({key:'StartScene'});
	}
	init(data){
		this.winner = data.winner;
	}
	create(){
		this.add.text(50, 50, 'Geometry Racing', {fontSize: 32} )
		this.add.text(50,200, 'Controls: ', {fontSize: 22} );

		this.add.text(50,240, 'Player ' , {fontSize:18} );
		this.add.text(120,240, 'Blue ', {fontSize:18, color: 'blue'} );
		this.add.text(170,240, ': Jump with W, Dash with D, Brake with Q', {fontSize:18} );
	
		this.add.text(50,265, 'Player ', {fontSize:18} );
		this.add.text(120,265, 'Red ', {fontSize:18, color: 'red'} );
		this.add.text(170,265, ': Jump with J, Dash with M, Brake with H', {fontSize:18} );

		this.add.text(50,330, 'Press space to start.', {fontSize: 28} );
		this.key = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE);
	}
	update(){
		if (this.key.isDown) {
			this.scene.start('MainScene', {lives:[5,5]});
		}
	}
}

export default StartScene;