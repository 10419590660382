class ObstacleCreator {

	constructor(scene) {
		this.scene = scene;
	}
	create(){
		let obstacle = this.scene.add.rectangle(900, 500, 50, 50, 0x8800FF);
		let pObstacle  = this.scene.matter.add.rectangle(200, 300, 50, 50, {
			ignoreGravity: true,
			ignorePointer: true,
			mass: 5,
		});
		this.obstacle = this.scene.matter.add.gameObject(obstacle,pObstacle)
		this.timedEvent = this.scene.time.addEvent({delay:10000, callback:this.onEvent, callbackScope:this,  loop:true});
		
		let obstacle2 = this.scene.add.rectangle(900,700, 150, 150, 0xFFA500);
		let pObstacle2 = this.scene.matter.add.rectangle(5000, 300,150, 150, {
			ignoreGravity: true,
			ignorePointer: true,
			mass: 300,
		});

		let obstacle3 = this.scene.add.rectangle(900,700, 200, 200, 0xFF6961);
		let pObstacle3 = this.scene.matter.add.rectangle(2000, 700, 200, 200, {
			ignoreGravity: true,
			ignorePointer: true,
			mass: 400,
		}); 
		this.obstacle2 = this.scene.matter.add.gameObject(obstacle2,pObstacle2);

		this.obstacle3 = this.scene.matter.add.gameObject(obstacle3,pObstacle3);
		
		const [width, height] = [30, 400];
		let obstacle4 = this.scene.add.rectangle(2000, 200, width, height, 0x77DD77 );
		let pObstacle4 = this.scene.matter.add.rectangle(2000,200, width, height, {
			ignoreGravity: true,
			mass: 450
		});
		this.obstacle4 = this.scene.matter.add.gameObject(obstacle4,pObstacle4); 
	}
	onEvent(){
		const randomNumber = Math.random();
		if (randomNumber < 0.33) {
			if (this.obstacle2.x < -200) {
				this.obstacle2.x = 1200;
				let randomHeight = 200 +  Math.random() * 400;
				this.obstacle2.y = randomHeight;
			}
		}
		else if (randomNumber > 0.33 && randomNumber < 0.66) {
			if (this.obstacle4.y > 800) {
				this.obstacle4.y = -200;
				let randomXPosition = 500 +  Math.random() * 500;
				this.obstacle4.x = randomXPosition;
			}
		}
		else {
			if (this.obstacle3.x < -200) {
				this.obstacle3.x = 1200;
				let randomHeight = 200 +  Math.random() * 400;
				this.obstacle3.y = randomHeight;
			}
		}
	}
	update(){
		//obstacles
		this.obstacle.setVelocityX(-20);
		//this.obstacle.x -= 20;

		this.obstacle2.setVelocityX(-10);
		//this.obstacle2.x -=10;

		this.obstacle3.setVelocityX(-30);

		this.obstacle4.setVelocityY(15);

		if (this.obstacle.x < -200) {
			this.obstacle.x = 1200;
			let randomHeight = 200 +  Math.random() * 400;
			this.obstacle.y = randomHeight;
		}
	}
	
}

export default ObstacleCreator;