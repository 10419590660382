import Phaser from 'phaser';
import Player from './Player';
import ObstacleCreator from './ObstacleCreator';

class MainScene extends Phaser.Scene {

	constructor() {
		super({key:'MainScene'});
	}
	init(data){
		this.lives = data.lives;
	}
	
	create(){  
		console.log("one time"); 

		const keysP1 = {};

		keysP1.jump = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.W);
		keysP1.dash = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.D);
		keysP1.brake = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.Q);
		this.player1 = new Player(this, keysP1, 'player1');
		this.player1.create();

		const keysP2 = {};

		keysP2.jump = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.J);
		keysP2.dash = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.M);
		keysP2.brake = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.H);

		this.player2 = new Player(this, keysP2, 'player2');
		this.player2.create();

		//platforms

		let platform = this.add.rectangle(500, 500, 400, 20, 0xAAAAAA);
		let pPlatform  = this.matter.add.rectangle(500, 500, 400, 20, {
			ignoreGravity: true,
			ignorePointer: true,
			isStatic: true,
		});
		this.platform = this.matter.add.gameObject(platform, pPlatform);

		//spawn platform

		let platform2 = this.add.rectangle(500, 500, 400, 20, 0xAAAAAA);
		let pPlatform2  = this.matter.add.rectangle(200, 300, 400, 20, {
			ignoreGravity: true,
			ignorePointer: true,
			isStatic: true,
		});
		this.platform2 = this.matter.add.gameObject(platform2,pPlatform2)

		//obstacles 

		this.obstacleCreator = new ObstacleCreator(this);
		this.obstacleCreator.create();


		this.floor = this.matter.add.rectangle(600, 800, 4000, 50, {
			isSensor: true,
			ignoreGravity: true,
			onCollideCallback: (collisionEvent) => {

				const label = collisionEvent.bodyA.label;
				const playerNum = label.includes('1') ? 'red' : 'blue';
				const liveIndex = playerNum === 'blue' ? 1 : 0;
				
				if (label.includes('player')) {
					this.lives[liveIndex]--;
					this.scene.start('EndScene', {winner: playerNum, lives:this.lives });
				}
			}
		})
	}
	
	update(){
		//player 1
		this.player1.update();

		//player 2
		this.player2.update();

		//all
		this.platform.x = this.platform.x - 7;

		//spawn platform
		this.platform2.x = this.platform2.x - 7;

		this.obstacleCreator.update();

		
		if (this.platform.x < -200) {
			this.platform.x = 1200;
			let randomHeight = 200 +  Math.random() * 400;
			this.platform.y = randomHeight;
		}

		
	}
}

export default MainScene;