import style from './style.css';
import Phaser from 'phaser';
import MainScene from './MainScene';
import EndScene from './EndScene';
import StartScene from './StartScene';

console.log("Hello World");

const game = new Phaser.Game({
	width: 1000,
	height: 800,
	backgroundColor: '35AAA6',
	scene: [StartScene, MainScene, EndScene],
	physics: {
		default: 'matter',
		matter: {
			debug: false,
			setBounds: false,
			gravity: {x: 0, y:2}
		}
	}
})

console.log(game);