import Phaser from 'phaser';

class EndScene extends Phaser.Scene {
	constructor() {
		super({key:'EndScene'});
	}
	init(data){
		this.winner = data.winner;
		this.lives = data.lives;
	}
	create(){

		let playerColor = null

		if (this.winner === 'blue'){
			playerColor = 'blue'
		}else{
			playerColor = 'red'
		}
	
		this.text = this.add.text(50,50, 'player ', {fontSize: 26} );
		this.text = this.add.text(160,50,  this.winner, {fontSize: 26, color: playerColor} );
		this.text = this.add.text(220,50,' won.', {fontSize: 26} );

		this.add.text(50,150, 'player ', {fontSize: 22} );
		this.add.text(135,150,'blue ', {fontSize: 22, color: 'blue'} );
		this.add.text(195,150, 'lives : ' + this.lives[0], {fontSize: 22} );

		this.add.text(50,120, 'player ', {fontSize: 22} );
		this.add.text(140,120, 'red ', {fontSize: 22, color: 'red'} );
		this.add.text(195,120, 'lives : ' + this.lives[1], {fontSize: 22} );

	 	this.add.text(50,300, 'Press space to continue',  {fontSize : 28 });

		this.key = this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE);
	}
	update(){
		if (this.key.isDown) {
			if (this.lives[0] === 0 || this.lives[1] === 0) {
				this.scene.start('StartScene');
			} else {
				this.scene.start('MainScene');
			}
		}
	}
}

export default EndScene;